<template>
  <button
    :class="[
      'toolBoxItem',
      props.disabled === true ? 'toolBoxItem--disabled' : null,
    ]"
    type="button"
    :aria-disabled="props.disabled === true || undefined"
    :aria-label="props.label"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  disabled?: boolean,
  label: string,
}>(), {
  disabled: false,
})

const emit = defineEmits<{
  click: [e: MouseEvent]
}>()

const onClick = (e: MouseEvent) => {
  if (props.disabled === true) return

  emit('click', e)
}

</script>

<style lang="scss" scoped>

.toolBoxItem {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 1.6em;
  height: 1.6em;
  padding: 0;
  font-size: var(--font-size-large);
  line-height: 1;
  color: inherit;
  background-color: transparent;
  border: solid 1px transparent;
  border-radius: .5em;
  color: var(--color-header-text);
  transition:
    color var(--transition-slow),
    background-color var(--transition-slow),
    border-color var(--transition-slow),
    box-shadow var(--transition-slow)
  ;

  @media (min-width: 400px) {
    font-size: var(--font-size-xlarge);
  }

  &--disabled {
    opacity: .5;
  }

  html.no-touch & {
    &:hover,
    &:focus-visible {
      outline: 0;
      border-color: rgba(255,255,255,.2);
    }

    &:not(&--disabled) {
      &:hover,
      &:focus-visible {
        color: var(--color-primary-light);
        background-color: var(--color-primary-glow);
        box-shadow: 0 0 0.5em 0.5em var(--color-primary-glow);
      }
    }
  }
}

</style>
