<template>
  <div class="spinner">
    <i-mdi-loading
      :class="[
        'spinner__icon',
        props.bright === true ? 'spinner__icon--bright' : null,
      ]"
    />
    <span class="sr-only">Ladataan</span>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  bright?: boolean,
}>(), {
  bright: false,
})

</script>

<style lang="scss" scoped>

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 1em 0;

  &__icon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: .5;
    font-size: 2.5em;
    color: var(--color-text);

    &--bright {
      color: var(--color-header-text);
    }
  }
}

</style>
