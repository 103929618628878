<template>
  <div class="toolBox">
    <slot />
  </div>
</template>

<style lang="scss" scoped>

.toolBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

</style>
