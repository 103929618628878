
<template>
  <Bartender
    class="site"
    :style="currentTheme.vars"
  >
    <BartenderContent
      ref="siteContentComponent"
      class="site__content"
    >
      <Header sticky>
        <HeaderCol>
          <h1 class="header__title sr-only">
            Uutissivu - Tuoreimmat uutiset puhelimessasi
          </h1>
          <button
            class="logo"
            aria-label="Takaisin sivun alkuun"
            @click="scrollToTop"
          >
            <span aria-hidden="true">
              Uutissivu
            </span>
          </button>
        </HeaderCol>
        <HeaderCol fill>
          <form
            action=""
            method="post"
            role="search"
            @submit.prevent="focusToMainEl"
          >
            <InputText
              v-model="searchQuery"
              type="text"
              label="Etsi artikkelia"
              placeholder="Haku"
            />
          </form>
        </HeaderCol>
        <HeaderCol>
          <ToolBox>
            <ToolBoxItem
              label="Päivitä artikkelilistaus"
              :disabled="articlesApi.loading.value"
              @click="refreshArticleList"
            >
              <i-mdi-refresh />
              <Badge :visible="!!newArticlesProcessor.filteredArticles.value.length" />
            </ToolBoxItem>
            <ToolBoxItem
              label="Asetukset"
              v-bartender-open="'settings'"
            >
              <i-mdi-cog />
            </ToolBoxItem>
          </ToolBox>
        </HeaderCol>
      </Header>
      <Spinner v-if="articlesApi.loading.value" />
      <Notification
        v-else-if="articlesApi.error.value"
        :text="articlesApi.error.value"
      >
        <Button
          @click="loadArticles"
          text="Yritä uudelleen"
        />
      </Notification>
      <main
        v-else
        ref="mainEl"
        class="main"
        tabindex="-1"
      >
        <ArticleList
          v-if="articlesProcessor.visibleArticles.value.length"
          :items="articlesProcessor.visibleArticles.value"
          :clicks="articleClicks"
          :new-article-ids="newArticleIds"
        />
        <Notification
          v-else
          text="Haulla ei löytynyt artikkeleita :("
        />
      </main>
    </BartenderContent>
    <BartenderBar
      class="configBar"
      name="settings"
      position="right"
      mode="float"
      :focus-trap="true"
      aria-label="Asetukset. Sulje painamalla ESC-näppäintä."
    >
      <Config />
    </BartenderBar>

    <UpdateNotification
      v-if="updateAvailable === true"
      @update="updateSW(true)"
    />
  </Bartender>
</template>

<script setup lang="ts">

import type { Article, ArticleClicks } from '@/types'
import {
  type Ref,
  ref,
  onMounted,
  watch,
  nextTick
} from 'vue'
import { searchQuery, config, currentTheme } from '@/lib/config'
import { useApi } from '@/lib/api'
import { useArticleProcessor } from '@/lib/ArticleProcessor'
import { useScroll, useInterval } from '@vueuse/core'
import {
  Bartender,
  BartenderContent,
  BartenderBar
} from '@fokke-/vue-bartender.js'
import ArticleList from '@/components/ArticleList.vue'
import { registerSW } from 'virtual:pwa-register'

/**
 * When service worked has been updated, show update notification
 */
const updateAvailable: Ref<boolean> = ref(false)
const updateSW = registerSW({
  onNeedRefresh () {
    updateAvailable.value = true
  },
  onOfflineReady () {},
})

/**
 * Reference to site content component
 */
const siteContentComponent = ref<InstanceType<typeof BartenderContent> | undefined>(undefined)

/**
 * Reference main element
 */
const mainEl: Ref<HTMLElement | undefined> = ref(undefined)

/**
 * Processor for articles
 */
const articlesProcessor = useArticleProcessor({
  chunks: true,
  autoInsertNextChunk: true,
})

/**
 * API interface for articles
 */
const articlesApi = useApi({
  loadingDefault: true,
})

/**
 * Load articles and store them to the articles processor
 */
const loadArticles = async (): Promise<Article[]> => {
  const response = await articlesApi.request('articles/').catch(() => {
    articlesApi.error.value = 'Artikkeleiden lataaminen ei onnistunut :('
  })

  if (!response) return Promise.resolve([])

  articlesProcessor.setArticles(response)

  return Promise.resolve(response)
}

/**
 * Processor for new articles
 */
const newArticlesProcessor = useArticleProcessor({
  afterTimestamp: articlesProcessor.latestTimestamp,
})

/**
 * API interface for polling new articles
 */
const articlesPollingApi = useApi()

/**
 * Poll new articles and store them to the new articles processor
 */
const articlesPolling = useInterval(
  import.meta.env.MODE === 'development' ? 10000 : 120000,
  {
    controls: true,
    callback: async () => {
      if (
        // Articles API resulted in an error
        articlesApi.error.value

        // Articles API is already loading
        || articlesApi.loading.value === true

        // Polling is disabled
        || config.value.pollArticles === false

        // Polling is already loading
        || articlesPollingApi.loading.value === true
      ) return

      const response = await articlesPollingApi.request('articles/').catch(() => {})
      if (!response) return

      articlesPolling.reset()
      newArticlesProcessor.setArticles(response)
    },
  }
)

/**
 * Article clicks
 */
const articleClicks: Ref<ArticleClicks> = ref({})

/**
 * API interface for article clicks
 */
const clicksApi = useApi()

/**
 * Load article clicks
 */
const loadArticleClicks = async () => {
  const response = await clicksApi.request('clicks/').catch(() => {})
  if (!response) return

  articleClicks.value = response
}

/**
 * Poll article clicks
 */
const articleClicksPolling = useInterval(
  import.meta.env.MODE === 'development' ? 10000 : 30000,
  {
    controls: true,
    callback: async () => {
      await loadArticleClicks()

      articleClicksPolling.reset()
    },
  }
)

/**
 * Refresh article list
 */
const newArticleIds: Ref<number[]> = ref([])

const refreshArticleList = async () => {
  scrollToTop()

  if (newArticlesProcessor.filteredArticles.value.length) {
    // Store new article ID's
    newArticleIds.value = newArticlesProcessor.articles.value.reduce((acc: number[], article: Article) => {
      acc.push(article.id)
      return acc
    }, [])

    articlesProcessor.insertNewArticles(newArticlesProcessor.articles.value)
    newArticlesProcessor.clear()
  } else {
    newArticleIds.value = []
    await loadArticles()
  }

  focusToMainEl()
}

onMounted(async () => {
  document.documentElement.classList.add('ontouchstart' in document.documentElement ? 'touch' : 'no-touch')

  await loadArticleClicks()
  await loadArticles()

  const { y: siteContentScrollY } = useScroll(siteContentComponent.value?.$el)

  watch(siteContentScrollY, val => {
    if (articlesApi.loading.value === true) return

    if ((siteContentComponent.value?.$el.offsetHeight + val) >= (siteContentComponent.value?.$el.scrollHeight * 0.8)) {
      articlesProcessor.insertNextChunk()
    }
  })
})

/**
 * Scroll to top
 */
const scrollToTop = () => {
  if (!siteContentComponent.value) return

  siteContentComponent.value.$el.scroll(0, 0)
}

/**
 * Focus to <main> element
 */
const focusToMainEl = async () => {
  await nextTick()

  mainEl.value?.focus({
    preventScroll: true,
  })
}

</script>

<style lang="scss">

@import "@fontsource/oxygen/latin-400.css";
@import "@fontsource/oxygen/latin-700.css";
@import 'modern-normalize/modern-normalize.css';
@import '@fokke-/bartender.js/dist/bartender.scss';
@import "@/assets/mixins";
@import "@/assets/config";
@import "@/assets/base";
@import "@/assets/utils";

html {
  font-family: 'Oxygen', sans-serif;
  font-size: 15px;

  @media (min-width: 600px) {
    font-size: 16px;
  }
}

body,
.configBar {
  min-width: 300px;
}

.configBar {
  position: relative;
  width: 100%;
  max-width: 100%;
  color: var(--color-header-text);
  background: linear-gradient(-180deg, var(--color-header-light) 0%, var(--color-header) 50%);

  @media (min-width: 400px) {
    width: 80%;
    max-width: 400px;
  }

  &.bartender__bar--open {
    @include shadow('left');
  }
}

.site {
  &__content {
    overflow-x: hidden;
    overflow-y: scroll;
    color: var(--color-text);
    background: var(--color-background);
    transition: background-color var(--transition-normal);
    scroll-behavior: smooth;
  }
}

.logo,
.headerTitle {
  font-size: var(--font-size-medium);

  @media (min-width: 400px) {
    font-size: var(--font-size-large);
  }
}

.logo {
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  text-decoration-color: var(--color-primary-light);
  text-underline-offset: .4em;
  outline: 0;

  &:focus-visible {
    text-decoration: underline;
    text-decoration-color: var(--color-primary-light);
    text-underline-offset: .4em;
  }
}

.headerTitle {
  font-weight: var(--font-weight-normal);
}

main {
  outline: 0;
}

</style>
