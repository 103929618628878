export const baseTheme = {
  light: {
    'background': 'hsl(0, 0%, 95%)',
    'text': 'hsl(0, 0%, 5%)',
    'text-visited': 'hsl(0, 0%, 50%)',
    'header': 'hsl(0, 0%, 10%)',
    'header-light': 'hsl(0, 0%, 22%)',
    'header-text': 'hsl(0, 0%, 90%)',
    'primary': 'hsl(173, 100%, 25%)',
    'primary-medium-light': 'hsl(173, 100%, 35%)',
    'primary-light': 'hsl(173, 100%, 45%)',
    'primary-glow': 'hsla(173, 100%, 45%, .06)',
    'subtitle-bg': 'hsl(0, 0%, 90%)',
    'subtitle-border': 'hsl(0, 0%, 95%)',
    'article-border': 'rgba(0, 0, 0, .1)',
    'article-bg-hover': 'rgba(255, 255, 255, 1)',
    'field-bg': 'rgba(255,255,255,.03)',
    'field-bg-hover': 'rgba(255,255,255,.07)',
    'field-highlight': 'hsla(173, 100%, 45%, .06)',
    'shadow': 'rgba(0, 0, 0, .05)',
  },
  dark: {
    'background': 'hsl(0, 0%, 12%)',
    'text': 'hsl(0, 0%, 90%)',
    'primary': 'hsl(173, 100%, 35%)',
    'subtitle-bg': 'hsl(0, 0%, 15%)',
    'subtitle-border': 'hsl(0, 0%, 25%)',
    'article-border': 'rgba(255, 255, 255, .1)',
    'article-bg-hover': 'rgba(255, 255, 255, .03)',
  },
}

export const themes = [
  {
    name: 'black',
    label: 'Musta',
    light: {},
    dark: {},
  },
  {
    name: 'green',
    label: 'Vihreä',
    light: {
      'header': 'hsl(160, 100%, 8%)',
      'header-light': 'hsl(160, 100%, 15%)',
      'primary': 'hsl(160, 100%, 25%)',
      'primary-medium-light': 'hsl(160, 100%, 35%)',
      'primary-light': 'hsl(160, 100%, 45%)',
      'primary-glow': 'hsla(160, 100%, 45%, .06)',
    },
    dark: {
      'background': 'hsl(160, 100%, 6%)',
      'primary': 'hsl(160, 100%, 35%)',
      'primary-medium-light': 'hsl(160, 100%, 35%)',
      'primary-light': 'hsl(160, 100%, 45%)',
      'subtitle-bg': 'hsl(160, 100%, 10%)',
      'subtitle-border': 'hsl(160, 100%, 15%)',
    },
  },
  {
    name: 'blue',
    label: 'Sininen',
    light: {
      'header': 'hsl(200, 100%, 13%)',
      'header-light': 'hsl(200, 100%, 20%)',
      'header-text': 'hsl(0, 0%, 95%)',
      'primary': 'hsl(200, 100%, 35%)',
      'primary-medium-light': 'hsl(200, 100%, 35%)',
      'primary-light': 'hsl(200, 100%, 75%)',
      'primary-glow': 'hsla(200, 100%, 75%, .06)',
    },
    dark: {
      'background': 'hsl(200, 100%, 8%)',
      'primary': 'hsl(200, 100%, 45%)',
      'primary-medium-light': 'hsl(200, 100%, 45%)',
      'subtitle-bg': 'hsl(200, 100%, 15%)',
      'subtitle-border': 'hsl(200, 100%, 22%)',
    },
  },
]
