<template>
  <button
    v-if="props.text"
    type="button"
    class="button"
  >
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  text?: string,
}>(), {
  text: undefined,
})

</script>

<style lang="scss">

.button {
  padding: .5em 1em;
  color: #fff;
  background: var(--color-primary);
  border: solid 1px var(--color-primary);
  outline: 0;
  border-radius: var(--radius-small);
  text-underline-offset: .2em;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

</style>
