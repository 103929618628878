import {
  onBeforeUnmount
} from 'vue'
import {
  getUUID,
  releaseUUID
} from '@/lib/uuid'

export const useInputField = () => {
  const id = getUUID()

  onBeforeUnmount(() => {
    releaseUUID(id)
  })

  return {
    id,
  }
}
