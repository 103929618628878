<template>
  <span
    :class="[
      'badge',
      props.visible === true ? 'badge--visible' : null,
    ]"
  />
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  visible?: boolean,
}>(), { visible: false })

</script>

<style lang="scss" scoped>

@keyframes badge {
  0% {
    box-shadow:
      0 0 .3em 0 #ffa500,
      inset 0 0 .3em #ffa500
    ;
  }

  50% {
    box-shadow:
      0 0 .5em .1em #ffa500,
      inset 0 0 .3em lighten(#ffa500, 30)
    ;
  }

  100% {
    box-shadow:
      0 0 .3em 0 #ffa500,
      inset 0 0 .3em #ffa500
    ;
  }
}

.badge {
  opacity: 0;
  top: .2em;
  right: .2em;
  position: absolute;
  width: .45em;
  height: .45em;
  background: lighten(#ffa500, 30);
  border-radius: 100%;
  transform: scale(.5);
  transition:
    opacity var(--transition-normal),
    transform var(--transition-normal)
  ;
  will-change: opacity, transform;
  animation: badge 1500ms infinite;

  &--visible {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
