<template>
  <div class="inputSwitch">
    <input
      :id="id"
      type="checkbox"
      v-model="modelValue"
      :value="value"
      :disabled="props.disabled"
      class="inputSwitch__input sr-only"
    >
    <label
      :for="id"
      class="inputSwitch__label"
    >
      <span class="inputSwitch__text">
        {{ props.label }}
      </span>

      <span class="switch">
        <span class="switch__toggle" />
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">

import { useInputField } from '@/composables/inputfield'

const props = withDefaults(defineProps<{
  label?: string,
  value?: string,
  disabled?: boolean,
}>(), {
  label: undefined,
  value: undefined,
  disabled: false,
})

const modelValue = defineModel<boolean | string | number | Array<string | number>>({
  default: false,
})

const { id } = useInputField()

</script>

<style lang="scss" scoped>

.inputSwitch {
  position: relative;
  display: block;
  background-color: var(--color-field-bg);
  will-change: background-color;
  transition: background-color var(--transition-normal);
  text-underline-offset: .2em;

  &:first-child {
    border-top-left-radius: var(--radius-small);
    border-top-right-radius: var(--radius-small);
  }

  &:last-child {
    border-bottom-left-radius: var(--radius-small);
    border-bottom-right-radius: var(--radius-small);
  }

  &:not(:first-child) {
    margin-top: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  html.no-touch & {
    &:hover,
    &:has(.inputSwitch__input:focus-visible) {
      background-color: var(--color-field-bg-hover);
    }
  }

  &:has(.inputSwitch__input:focus-visible) {
    text-decoration: underline;
  }

  &__label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1em;
    line-height: 1.5;
    padding: .8em var(--spacing-single);
    border-radius: var(--radius-small);

    .inputSwitch:has(.inputSwitch__input:disabled) & {
      cursor: not-allowed;
    }

    .inputSwitch:has(.inputSwitch__input:not(:disabled)) & {
      cursor: pointer;
    }
  }

  &__text {
    display: block;
    flex: 1 1 auto;
  }
}

.switch {
  display: block;
  flex: 0 0 1.8em;
  height: 1.2em;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 10em;
  box-shadow: none;
  transition:
    background-color var(--transition-normal),
    box-shadow var(--transition-normal)
  ;
  will-change: background-color, box-shadow;

  .inputSwitch:has(.inputSwitch__input:checked) & {
    background-color: var(--color-primary-medium-light);
    box-shadow: 0 0 1em 1em var(--color-primary-glow);
  }

  &__toggle {
    display: block;
    width: 1em;
    height: 1em;
    background-color: var(--color-header-text);
    border-radius: 50%;
    transform: translate(.1em, .1em);
    will-change: background-color, transform;
    transition:
      background-color var(--transition-normal),
      transform var(--transition-normal)
    ;

    .inputSwitch:has(.inputSwitch__input:checked) & {
      transform: translate(.7em, .1em);
      background-color: #fff;
    }
  }
}

</style>
