<template>
  <div
    :class="[
      'headerCol',
      props.fill === true ? 'headerCol--fill' : null,
    ]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  fill?: boolean,
}>(), { fill: false })

</script>

<style lang="scss" scoped>

.headerCol {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: auto;

  &--fill {
    flex: 1 1 auto;
  }
}

</style>
