<template>
  <fieldset
    v-if="!!$slots.default"
    class="fieldset"
  >
    <legend
      v-if="!!$slots.icon || !!$slots.legend"
      class="legend"
    >
      <span
        v-if="!!$slots.icon"
        class="legend__icon"
        aria-hidden="true"
      >
        <slot name="icon" />
      </span>
      <span
        v-if="!!$slots.legend"
        class="legend__text"
      >
        <slot name="legend" />
      </span>
    </legend>

    <div class="fieldset__content">
      <slot />
    </div>
  </fieldset>
</template>

<style lang="scss" scoped>

.fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;

  &:not(:last-child) {
    margin-bottom: var(--spacing-double);
  }
}

.legend {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: var(--spacing-single);
  font-size: var(--font-size-medium);

  &__icon,
  &__text {
    display: block;
  }

  &__icon {
    position: relative;
    font-size: 1.2em;
    margin-right: .75em;
    color: var(--color-primary-light);

    &:before {
      z-index: -1;
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      display: block;
      width: 0px;
      height: 2px;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 1.5em 1.5em var(--color-primary-glow);
    }
  }

  &__text {
    flex: 1 1 auto;
    line-height: 1;
  }
}

</style>
