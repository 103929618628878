import type { Ref } from 'vue'
import type {
  SearchTarget,
  Config,
  CurrentTheme,
  ColorList,
  CssVarList
} from '@/types'
import {
  ref,
  computed,
  watch
} from 'vue'
import { baseTheme, themes } from '@/lib/theme'

// Search targets
export const searchTargets: SearchTarget[] = [
  {
    name: 'title',
    label: 'Otsikko',
  },
  {
    name: 'feed',
    label: 'Lähde',
  },
  {
    name: 'category',
    label: 'Kategoria',
  },
]

// Search query
export const searchQuery: Ref<string> = ref('')

// Default app config
export const defaultConfig = {
  colorTheme: 'black',
  darkMode: window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)').matches : false,
  showImages: true,
  openInNewTab: false,
  pollArticles: true,
  showDuplicates: false,
  hideKatso: false,
  hideQuestionMark: false,
  searchTargets: searchTargets.flatMap(item => item.name),
  hiddenFeeds: [],
}

// Current app config
export const config: Ref<Config> = ref({
  colorTheme: 'black',
  darkMode: window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)').matches : false,
  showImages: true,
  openInNewTab: false,
  pollArticles: true,
  showDuplicates: false,
  hideKatso: false,
  hideQuestionMark: false,
  searchTargets: searchTargets.flatMap(item => item.name),
  hiddenFeeds: [],
  ...(() => {
    const userConfig = JSON.parse(localStorage.getItem('appConfig') || '{}')
    if (!Object.keys(userConfig).length) return userConfig

    if (!themes.find(item => item.name === userConfig.colorTheme)) {
      delete userConfig.colorTheme
    }

    return userConfig
  })(),
})

// When config changes, save it to localStorage
watch(config, (val) => {
  if (typeof val !== 'object') return

  localStorage.setItem('appConfig', JSON.stringify(val))
}, { deep: true })

// Current color theme
export const currentTheme = computed<CurrentTheme>(() => {
  const theme = themes.find(item => item.name === config.value.colorTheme) || themes.find(item => item.name === 'black')

  if (!theme) {
    return {
      name: 'undefined',
      label: 'undefined',
      colors: {},
      vars: {},
    }
  }

  const colors: ColorList = {
    // Light variant of base theme
    ...baseTheme.light,

    // Merge dark variant of base theme, if requested
    ...(config.value.darkMode === true ? baseTheme.dark : {}),

    // Light variant of user selected theme
    ...theme.light,

    // Merge dark variant of user selected theme, if requested
    ...(config.value.darkMode === true ? theme.dark : {}),
  }

  const vars = Object.entries(colors).reduce((acc, [
    key,
    value,
  ]) => {
    acc[`--color-${key}`] = value

    return acc
  }, {} as CssVarList)

  return {
    name: theme.name,
    label: theme.label,
    colors,
    vars,
  }
})

// Update meta theme color when theme changes
watch(currentTheme, (val) => {
  document.querySelector('meta[name="theme-color"]')?.setAttribute('content', val.colors['header-light'])
}, {
  immediate: true,
})
