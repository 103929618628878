<template>
  <header
    :class="[
      'header',
      props.sticky === true ? 'header--sticky' : null,
      props.transparent === true ? 'header--transparent' : 'header--solid',
    ]"
  >
    <slot />
  </header>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  transparent?: boolean,
  sticky?: boolean
}>(), {
  transparent: false,
  sticky: false,
})

</script>

<style lang="scss" scoped>

.header {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--spacing-half);
  padding: 0 var(--spacing-section);
  color: var(--color-header-text);
  height: var(--header-height);

  @media (min-width: 400px) {
    gap: var(--spacing-single);
  }

  &--sticky {
    position: sticky;
    top: 0;
  }

  &--solid {
    background: linear-gradient(-180deg, var(--color-header-light) 0%, var(--color-header) 80%);
  }

  &--transparent {
    background: none;
  }

  &__title {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    color: inherit;
  }
}
</style>
