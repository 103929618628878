export const uuidTable: string[] = []

export const getUUID = (): string => {
  const id = Math.random().toString(36).substring(2, 10)

  if (uuidTable.includes(id)) return getUUID()

  uuidTable.push(id)

  return id
}

export const releaseUUID = (id: string): string[] => {
  uuidTable.splice(uuidTable.indexOf(id), 1)

  return uuidTable
}
