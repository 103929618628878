<template>
  <div class="updateNotification">
    <div
      :class="[
        'updateNotification__wrap',
        visible === true ? 'updateNotification__wrap--visible' : ''
      ]"
    >
      <div class="updateNotification__content">
        <p class="updateNotification__text">
          Uusi versio on saatavilla!
        </p>
      </div>
      <button
        class="updateNotification__button"
        @click="emit('update')"
      >
        Päivitä
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">

import { type Ref, ref, onMounted } from 'vue'

const visible: Ref<boolean> = ref(false)

const emit = defineEmits<{
  update: []
}>()

onMounted(async () => {
  await new Promise(resolve => setTimeout(resolve, 1))

  visible.value = true
})

</script>

<style lang="scss">

@import "@/assets/mixins";

.updateNotification {
  z-index: 99999;
  position: fixed;
  left: 50%;
  bottom: var(--spacing-section);
  color: var(--color-header-text);
  transform: translateX(-50%);

  &__wrap {
    @include shadow();
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-radius: var(--radius-small);
    opacity: 0;
    transform: scale(0.7);
    transition:
      opacity var(--transition-normal),
      transform var(--transition-normal)
    ;

    &--visible {
      opacity: 1;
      transform: scale(1);
    }

    @media (min-width: 400px) {
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1em;
    text-align: center;
    background: linear-gradient(-180deg, var(--color-header-light) 0%, var(--color-header) 60%);

    @media (min-width: 400px) {
      text-align: left;
    }
  }

  &__text {
    margin: 0;
    white-space: nowrap;
  }

  &__button {
    display: block;
    outline: 0;
    border: 0;
    padding: 1em;
    color: #fff;
    background: var(--color-primary);
    text-underline-offset: .4em;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

</style>
