<template>
  <div
    v-if="props.text"
    class="notification"
  >
    <p class="notification__text">
      {{ props.text }}
    </p>
    <div
      v-if="!!$slots.default"
      class="notification__footer"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  text?: string,
}>(), {
  text: undefined,
})

</script>

<style lang="scss" scoped>

.notification {
  padding: 1em;
  text-align: center;

  &__text {
    &:not(:last-child) {
      margin-bottom: var(--spacing-section);
    }
  }
}

</style>
